import { Center, Space, Text } from '@mantine/core';

interface BannerCloserRegistrationProps {
  type: 'all' | '21kmCarabinieri';
}

export default function BannerCloserRegistration({
  type,
}: BannerCloserRegistrationProps) {
  return (
    <div>
      <Text size="lg" fw={700}>
        Attenzione le iscrizioni sono chiuse!
      </Text>
      <Space h="md" />
      <Space h="md" />
      <Center>
        <Text>
          {type === 'all'
            ? 'Le iscrizioni alla Maratona M6C sono terminate.'
            : 'Le iscrizioni alla Maratona 21kmCarabinieri sono terminate.'}
        </Text>
      </Center>{' '}
      <Space h="md" />
      <Space h="md" />
    </div>
  );
}
