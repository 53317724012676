import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import logo from '@images/logo_M6C.svg';
import {
  Button,
  Checkbox,
  Container,
  Divider,
  Group,
  Select,
  Space,
  Stepper,
  Table,
  Tabs,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { openModal } from '@mantine/modals';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconUser } from '@tabler/icons-react';

import env from '@config/env';

import {
  PriceByCouponResponse,
  useLazyCheckCouponQuery,
} from '@api/couponMarathon.api';
import { isApiError } from '@api/index';
import { useGetMarathonsQuery } from '@api/marathons.api';
import { MarathonType, SubscribersGender } from '@api/registrations.api';
import {
  checkFidalResponse,
  RaceLengthType,
  SubscriberDataDto,
  SubscribersCertificateExpiry,
  TipologyCardSubscriber,
  useLazyCheckSubscriptionFidalQuery,
  useRegisterSubscriberMutation,
  useUploadImagesMutation,
} from '@api/subscribers.api';

import useScript from '@hooks/useScript';

import BannerCloserRegistration from '@components/BannerCloserRegistration';
import DataSummaryM6C from '@components/DataSummaryM6C';
import Icon from '@components/Icon';
import ModalPaymentStripe from '@components/ModalPaymentStripe';
import RegisterFormM6C from '@components/RegisterFormM6C';

import statesData from '../../data/nation.json';
import { requiredValidator } from '../../utils/forms';
import { formatMarathonOption, labelMarathon } from '../../utils/mapLabel';
import classes from './RegistrationsM6C.module.css';

const checkErrorFidal = (val: string) => {
  switch (val) {
    case 'Utente disabilitato':
      return false;
    case 'Tesserato non trovato':
      return false;
  }
  return true;
};

const checkMarathonFidal = (val: string) => {
  switch (val) {
    case 'La10M6C':
      return true;
    case 'MezzaMaratona21km':
      return true;
  }
  return false;
};

export default function RegistrationsM6C() {
  // ==========================================================================
  // General
  // ==========================================================================
  const [searchParams, setSearchParams] = useSearchParams();
  useScript(
    'https://www.google.com/recaptcha/api.js?render=6LezXVcnAAAAAH8fH9hAa-V_K5JFD3tdVJyTz7Lc',
  );

  // ==========================================================================
  // State
  // ==========================================================================
  const [activeStep, setActiveStep] = useState(0);
  const [activeTab, setActiveTab] = useState('firstPartecipant');
  const [isLoading, setIsLoading] = useState(true);

  const [marathonChosen, setMarathonChosen] = useState<string | null>(
    searchParams.get('percorso') || null,
  );
  const [tipologyCardSubscriber, setTipologyCardSubscriber] =
    useState<TipologyCardSubscriber | null>(null);
  const [priceByCoupon, setPriceByCoupon] = useState<PriceByCouponResponse>();
  const [isLoadingOnSubmit, setIsLoadingOnSubmit] = useState(false);

  const [isRegistrationsClosed, setIsRegistrationsClosed] = useState(false);
  // ==========================================================================
  // Api
  // ==========================================================================

  // Register subscriber and create order Stripe
  const [registerSubscriber, { isLoading: isLoadingSubscriber }] =
    useRegisterSubscriberMutation();

  // Get Marathons
  const { data: marathons = [], isLoading: isLoadingMarathons } =
    useGetMarathonsQuery({ category: 'M6C' });

  const [uploadImage, { isLoading: isLoadingUploadImage }] =
    useUploadImagesMutation();

  // Check Fidal
  const [getCheckFidalResponse, { isLoading: isLoadingFidal }] =
    useLazyCheckSubscriptionFidalQuery();

  const [checkCoupon, { isLoading: isLoadingCheckCcoupon }] =
    useLazyCheckCouponQuery();

  // Close registrations
  useEffect(() => {
    const deadline = new Date(Date.UTC(2024, 8, 20, 12, 59, 59));

    const now = new Date();

    const timezoneOffset = now.getTimezoneOffset() * 60000;

    const nowLocal = new Date(now.getTime() - timezoneOffset);

    if (nowLocal.getTime() > deadline.getTime()) {
      setIsRegistrationsClosed(true);
    } else {
      setIsRegistrationsClosed(false);
    }
  }, []);

  // ==========================================================================
  // Form
  // ==========================================================================

  const initialValues = {
    participants: [
      {
        name: '',
        surname: '',
        birthDate: null as Date | null,
        gender: 'male',
        nationality: 'Italiana',
        address: '',
        city: '',
        provincie: '',
        cap: '',
        state: statesData[107].name,
        email: '',
        phone: '',
        companyName: '',
        condition: false,
        cardNumber: '',
        certificateDateExpiry: null as Date | null,
        raceLength: null as RaceLengthType | null,
        certificateExpiry: null as SubscribersCertificateExpiry | null,
        certificateFile: null as File | null,
      },
    ],
    privacy: false,
    coupon: '',
    teamName: '',
  };

  const form = useForm({
    initialValues,
    validate: {
      participants: {
        name: (val) => requiredValidator(val),
        surname: (val) => requiredValidator(val),
        gender: (val) =>
          val === 'male' || val === 'female' ? null : 'Campo obbligatorio',
        nationality: (val) => requiredValidator(val),
        address: (val) => requiredValidator(val),
        city: (val) => requiredValidator(val),
        birthDate: (val) => {
          if (!val) return 'Campo obbligatorio';

          const eighteenYearsAgo = new Date();
          eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

          if (val > eighteenYearsAgo) {
            return 'Devi avere almeno 18 anni';
          }

          return null;
        },
        provincie: (val) =>
          /^[a-zA-Z]+$/.test(val) ? null : 'Può contenere solo lettere',
        state: (val) => requiredValidator(val),
        email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Email invalida'),
        phone: (val) => requiredValidator(val),
        condition: (val) => {
          return !val ? 'Campo obbligatorio' : null;
        },
        cardNumber: (val) => {
          if (
            val.trim() === '' &&
            (marathonChosen === 'La10M6C' ||
              marathonChosen === 'MezzaMaratona21km')
          ) {
            return 'Campo obbligatorio';
          }
          return null;
        },
        raceLength: (val, values, path) => {
          if (marathonChosen === 'StaffettaMiTi') {
            const currentPartecipantIndex = +path.split('.')[1];
            const otherPartecipantIndex = currentPartecipantIndex === 0 ? 1 : 0;
            const otherRaceLength =
              values.participants[otherPartecipantIndex]?.raceLength;
            if (val === undefined || val === null) {
              return 'Campo obbligatorio';
            } else if (val === otherRaceLength) {
              return 'I due partecipanti non possono selezionare la stessa lunghezza di gara';
            }
          }
          return null;
        },
        certificateFile: (val) => {
          const fileType = ['image/jpeg', 'image/jpg', 'application/pdf'];

          if (val) {
            if (val.size > 4000000) {
              return 'La massima dimensione consentita è 4MB';
            } else if (!fileType.includes(val.type)) {
              return 'Il tipo di file non è supportato. Sono accettati solo file JPG o PDF';
            }
          }

          return null;
        },
      },
      teamName: (val) => {
        if (marathonChosen === 'StaffettaMiTi') {
          return val === '' ? 'Campo obbligatorio' : null;
        }
      },
      privacy: (val) =>
        val === false ? 'Impossibile proseguire. Accettare la privacy' : null,
    },
  });

  useEffect(() => {
    if (marathonChosen === 'StaffettaMiTi') {
      form.insertListItem('participants', {
        name: '',
        surname: '',
        birthDate: new Date(),
        gender: '',
        nationality: 'Italiana',
        address: '',
        city: '',
        provincie: '',
        cap: '',
        state: 'Italia',
        email: '',
        phone: '',
        condition: false,
        cardNumber: '',
        companyName: '',
        raceLength: null as RaceLengthType | null,
        certificateFile: null as File | null,
        certificateDateExpiry: null as Date | null,
        certificateExpiry: null as SubscribersCertificateExpiry | null,
      });

      return () => form.removeListItem('participants', 1);
    } else if (form.values.participants.length > 1) {
      form.removeListItem('participants', 1);
      // Go to tab 1
      setActiveTab('firstPartecipant');
    }
    form.values.participants.map((v) => (v.companyName = ''));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marathonChosen]);

  const subscriberRegister = async (
    values: typeof initialValues,
    token?: string,
  ) => {
    try {
      setIsLoadingOnSubmit(true);
      const data: SubscriberDataDto[] = values.participants.map((item) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { condition, ...rest } = item;
        return {
          ...rest,
          typeCard: tipologyCardSubscriber,
        };
      });

      const response = await registerSubscriber({
        subscriberDataDto: data,
        marathon: marathonChosen!,
        teamName: values.teamName,
        coupon: values.coupon,
        recaptchaToken: token!,
      }).unwrap();

      if (response) {
        values.participants.forEach(async (p, index) => {
          const formData = new FormData();
          formData.append(
            'participants_id',
            response.subscriberDataWithPayment[index].id.toString(),
          );

          formData.append(
            'participants_name',
            response.subscriberDataWithPayment[index].name +
              response.subscriberDataWithPayment[index].surname,
          );

          if (p.certificateFile) {
            formData.append('participants_certificate', p.certificateFile);
          }

          await uploadImage(formData).unwrap();
        });

        const paymentStatus = response.paymentStatus;
        const clientSecret = response.stripeClientSecret;
        if (paymentStatus === 'inProgress' && clientSecret) {
          handleOpenModalStripeClick(clientSecret);
        } else if (
          paymentStatus === 'completed' &&
          priceByCoupon?.price === 0
        ) {
          showNotification({
            title: 'Iscrizione riuscita',
            message: 'Riceverai una mail di conferma',
            icon: <Icon icon={IconCheck} />,
            color: 'teal',
          });
          window.location.href = `https://m6c.it/thankyoupage/`;
        }
      }
    } catch (e) {
      if (isApiError(e)) {
        if (e.status === 401) {
          form.setErrors({ general: e.data.message });
        } else if (e.status === 400) {
          form.setErrors({ general: e.data.message, ...e.data.errors });
          prevStep();
        } else {
          form.setErrors({
            general: 'Errore imprevisto, controlla i campi e riprova',
          });
        }
      }
    } finally {
      setIsLoadingOnSubmit(false);
    }
  };

  const onSubmit = async (values: typeof initialValues) => {
    if (env.MODE === 'production') {
      // @ts-ignore
      window['grecaptcha'].ready(function () {
        // @ts-ignore
        window['grecaptcha']
          .execute(env.RECAPTCHA_PUBLIC_KEY, { action: 'submit' })
          .then(async (token: string) => {
            subscriberRegister(values, token);
          });
      });
    } else {
      subscriberRegister(values);
    }
  };

  useEffect(() => {
    if (
      isLoadingSubscriber &&
      isLoadingCheckCcoupon &&
      isLoadingMarathons &&
      isLoadingUploadImage &&
      isLoadingFidal &&
      !isLoading
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isLoadingSubscriber,
    isLoadingFidal,
    isLoadingUploadImage,
    isLoadingCheckCcoupon,
    isLoadingMarathons,
  ]);

  // ==========================================================================
  // Handlers
  // ==========================================================================

  const nextStep = async () => {
    await form.validate();

    let responseFidal: checkFidalResponse = {} as checkFidalResponse;
    let responseChceckCoupon: PriceByCouponResponse =
      {} as PriceByCouponResponse;

    if (checkMarathonFidal(marathonChosen ?? '')) {
      try {
        responseFidal = await getCheckFidalResponse({
          cardNumber: form.values.participants[0].cardNumber,
        }).unwrap();
      } catch (e) {
        if (isApiError(e)) {
          if (e.status === 400) {
            form.setErrors({ general: e.data.message, ...e.data.errors });
          } else if (e.status === 401) {
            form.setErrors({ general: e.data.message });
          }
        }
      }
    }

    if (form.isValid()) {
      const targetGenderSubscribers = form.values.participants.map(
        (p) => p.gender,
      ) as SubscribersGender[];

      responseChceckCoupon = await checkCoupon({
        marathon: marathonChosen as MarathonType,
        code: form.values.coupon,
        targetGender: targetGenderSubscribers,
      }).unwrap();

      setPriceByCoupon(responseChceckCoupon);

      if (
        marathonChosen !== '' &&
        marathons.some((marathon) => marathon.name === marathonChosen) &&
        responseChceckCoupon?.price !== undefined &&
        checkErrorFidal(responseFidal.response)
      ) {
        form.setFieldValue(
          `participants.0.certificateExpiry`,
          responseFidal.certificateExpiry,
        );

        setActiveStep((current) => (current < 3 ? current + 1 : current));
        setTipologyCardSubscriber(
          responseFidal.typeCard as TipologyCardSubscriber,
        );
      } else if (marathonChosen === '') {
        showNotification({
          title: 'Impossibile proseguire',
          message: 'Nessun percorso selezionato, seleziona un percorso',
          color: 'red',
        });
      } else if (responseChceckCoupon.price === undefined) {
        showNotification({
          title: 'Impossibile proseguire',
          message: 'Errore inatteso, impossibile calcolare il prezzo',
          color: 'red',
        });
      } else if (!checkErrorFidal(responseFidal.response)) {
        form.setErrors({
          general: 'Errore imprevisto, tesseramento non valido',
        });
        showNotification({
          title: 'Impossibile proseguire',
          message: 'Tesseramento non valido',
          color: 'red',
        });
      }
    }
  };

  const prevStep = () => {
    setActiveStep((current) => (current > 0 ? current - 1 : current));
  };

  const setMarathonParams = (val: string) => {
    setMarathonChosen(val);
    searchParams.set('percorso', val);
    setSearchParams(searchParams);
    form.values.participants[0].raceLength = null;
  };

  const handleOpenModalStripeClick = (value: string) => {
    openModal({
      title: <Text fw={700}>Pagamento ordine</Text>,
      children: (
        <ModalPaymentStripe
          price={priceByCoupon!.price}
          paymentClientSecret={value}
          onSuccess={() => {
            window.location.href = `https://m6c.it/thankyoupage/`;
          }}
        />
      ),
      closeOnClickOutside: false,
      withCloseButton: false,
    });
  };

  // ==========================================================================
  // Render
  // ==========================================================================

  return (
    <>
      <div className={classes.container}>
        <Container size="md" py="4rem">
          <Group justify="space-between">
            <Button>
              <Link to="https://m6c.it/" className={classes.linkStyle}>
                Torna al sito
              </Link>
            </Button>
            <Title c="white" visibleFrom="sm">
              Iscrizione M6C 2024
            </Title>
            <img src={logo} alt="logo" style={{ width: '10rem' }} />
          </Group>
          <div className={classes.itemRegistration}>
            {isRegistrationsClosed ? (
              <BannerCloserRegistration type="all" />
            ) : (
              <>
                {' '}
                {marathonChosen === '21kmCarabinieri' ? (
                  <>
                    <BannerCloserRegistration type="21kmCarabinieri" />
                    <Select
                      label="Percorso:"
                      required
                      value={marathonChosen}
                      data={marathons.map(formatMarathonOption)}
                      onChange={(value) => setMarathonParams(value!)}
                      allowDeselect={false}
                      className={classes.changeWidth}
                    />
                  </>
                ) : (
                  <form
                    onSubmit={form.onSubmit((values) => {
                      onSubmit(values);
                    })}
                  >
                    <Title c="#01336C" order={3} hiddenFrom="sm">
                      Iscrizione M6C 2024
                    </Title>

                    <Stepper active={activeStep}>
                      <Stepper.Step
                        label="Inserimento dati"
                        description="Iscrizione"
                      >
                        <Group justify="left" align="end" mt="1.3rem">
                          <Select
                            label="Percorso:"
                            required
                            value={marathonChosen}
                            data={marathons.map(formatMarathonOption)}
                            onChange={(value) => setMarathonParams(value!)}
                            allowDeselect={false}
                            className={classes.changeWidth}
                          />
                          <TextInput
                            label="Coupon:"
                            {...form.getInputProps('coupon')}
                            className={classes.changeWidth}
                          />
                        </Group>

                        <Space h="xl" />

                        <Tabs
                          value={activeTab}
                          onChange={(tab) => setActiveTab(tab!)}
                          color="#ff0000"
                          styles={{
                            list: {
                              display:
                                form.values.participants.length > 1
                                  ? 'flex'
                                  : 'none',
                            },
                          }}
                        >
                          <Tabs.List grow>
                            <Tabs.Tab
                              value="firstPartecipant"
                              leftSection={<Icon icon={IconUser} />}
                            >
                              Primo partecipante
                            </Tabs.Tab>
                            {form.values.participants.length > 1 && (
                              <Tabs.Tab
                                value="secondPartecipant"
                                leftSection={<Icon icon={IconUser} />}
                              >
                                Secondo partecipante
                              </Tabs.Tab>
                            )}
                          </Tabs.List>

                          <Tabs.Panel value="firstPartecipant">
                            {form.values.participants.length > 1 && (
                              <Space h="xl" />
                            )}

                            <RegisterFormM6C
                              form={form}
                              indexPartecipant={0}
                              isStaffetta={
                                marathonChosen === 'StaffettaMiTi'
                                  ? true
                                  : false
                              }
                              price={priceByCoupon?.price}
                              marathon={marathonChosen ?? ''}
                            />
                          </Tabs.Panel>

                          {form.values.participants.length > 1 && (
                            <Tabs.Panel value="secondPartecipant">
                              <Space h="xl" />
                              <RegisterFormM6C
                                form={form}
                                indexPartecipant={1}
                                isStaffetta={true}
                                price={priceByCoupon?.price}
                                marathon={marathonChosen ?? ''}
                              />
                            </Tabs.Panel>
                          )}
                        </Tabs>
                      </Stepper.Step>
                      <Stepper.Step
                        label="Riepilogo dati"
                        description="Controllo"
                      >
                        <div>
                          <DataSummaryM6C
                            form={form}
                            indexPartecipant={0}
                            marathon={marathonChosen ?? ''}
                            coupon={priceByCoupon?.isCouponValid}
                          />
                          {form.values.participants.length > 1 && (
                            <DataSummaryM6C
                              form={form}
                              indexPartecipant={1}
                              marathon={marathonChosen ?? ''}
                              coupon={priceByCoupon?.isCouponValid}
                            />
                          )}
                        </div>
                        <div>
                          <Space h="md" />

                          <Table striped visibleFrom="sm">
                            <Table.Thead>
                              <Table.Tr>
                                <Table.Th>Percorso</Table.Th>
                                {marathonChosen === 'StaffettaMiTi' && (
                                  <Table.Th>Nome squdra</Table.Th>
                                )}
                                <Table.Th>Partecipanti</Table.Th>
                                <Table.Th>Prezzo</Table.Th>
                              </Table.Tr>
                            </Table.Thead>
                            <Table.Tbody>
                              <Table.Tr>
                                <Table.Td>
                                  {labelMarathon(marathonChosen!)}
                                </Table.Td>
                                {marathonChosen === 'StaffettaMiTi' && (
                                  <Table.Td>
                                    {form?.values?.teamName
                                      ? form?.values?.teamName
                                      : ''}
                                  </Table.Td>
                                )}
                                <Table.Td>
                                  {form.values.participants[0].name +
                                    (marathonChosen === 'StaffettaMiTi'
                                      ? ` / ${
                                          form?.values?.participants[1]?.name
                                            ? form.values.participants[1].name
                                            : ''
                                        }`
                                      : '')}
                                </Table.Td>
                                <Table.Td>
                                  {Number(
                                    ((priceByCoupon?.price || 0) / 100).toFixed(
                                      2,
                                    ),
                                  ).toLocaleString('it-IT', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  })}{' '}
                                </Table.Td>
                              </Table.Tr>
                            </Table.Tbody>
                          </Table>
                          <Group justify="right" visibleFrom="sm">
                            <Text fz="0.65rem">
                              La quota comprende
                              <span style={{ fontWeight: 600 }}>
                                {' '}
                                pacco gara
                              </span>
                              ,{' '}
                              <span style={{ fontWeight: 600 }}>
                                medaglia
                              </span>{' '}
                              e{' '}
                              <span style={{ fontWeight: 600 }}>
                                pasta party finale.
                              </span>
                            </Text>
                          </Group>

                          <Group justify="right" mt="2rem">
                            <Text size="xl" fz="0.9rem">
                              TOTALE
                            </Text>
                          </Group>
                          <Group justify="right">
                            <Text size="xl" fw={700} fz="2rem">
                              {Number(
                                ((priceByCoupon?.price || 0) / 100).toFixed(2),
                              ).toLocaleString('it-IT', {
                                style: 'currency',
                                currency: 'EUR',
                              })}
                            </Text>
                          </Group>
                        </div>
                      </Stepper.Step>
                    </Stepper>

                    <Divider my="md" />
                    <Group justify="space-between">
                      {activeStep !== 0 && (
                        <>
                          <Button variant="default" onClick={prevStep}>
                            Indietro
                          </Button>

                          <Button
                            type="submit"
                            loading={isLoadingSubscriber && isLoadingOnSubmit}
                          >
                            {priceByCoupon?.price === 0
                              ? 'Iscriviti'
                              : 'Iscriviti e paga'}
                          </Button>
                        </>
                      )}

                      {activeStep < 1 && (
                        <>
                          <Group>
                            <Checkbox
                              {...form.getInputProps('privacy', {
                                type: 'checkbox',
                              })}
                              label={
                                <div className={classes.privacyPolicy}>
                                  <span>
                                    Autorizzo il trattamento dei miei dati
                                    personali
                                  </span>
                                  &nbsp;
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        '<a href="https://www.iubenda.com/privacy-policy/89956701" class="iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy "> Privacy Policy</a><script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>',
                                    }}
                                  ></div>
                                </div>
                              }
                            />
                          </Group>

                          <Button
                            onClick={nextStep}
                            className={classes.changeWidth}
                          >
                            Riepilogo
                          </Button>
                        </>
                      )}
                    </Group>
                    {form.isValid() && (
                      <Text c="red" size="md" mt="md">
                        {form.errors.general}
                      </Text>
                    )}
                  </form>
                )}
              </>
            )}
          </div>
        </Container>
      </div>
    </>
  );
}
